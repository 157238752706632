import Base from "./Base"
import WPDarkMode from "./WPDarkMode"

class Accessibility extends Base {
	/**
	 * Init
	 */
	ready() {
		this.trackKeyBoardPress();
		this.handleAnimation();
	}

	/**
	 * Track keyboard press
	 */
	trackKeyBoardPress() {

		// Bail, if accessibility is disabled.
		if ( ! wp_dark_mode_json.options.accessibility_enabled_keyboard_shortcut ) {
			return;
		}

		document.addEventListener('keydown', (e) => {
			// if pressed ctrl + alt + d
			if (e.ctrlKey && e.altKey && e.keyCode === 68) {
				WPDarkMode.toggle().remember();
			}
		});
	}

	/**
	 * Handle animation
	 */
	handleAnimation() {
		// Bail if animation_enabled is disabled.
		if ( ! wp_dark_mode_json.options.animation_enabled ) {
			return;
		}

		// Add animation class.
		if(WPDarkMode.isActive) {
			document.querySelector('html').classList.add('wp-dark-mode-animation');
		}

		WPDarkMode.onChange(() => {
			if( !WPDarkMode.isActive ) {
				document.querySelector('html').classList.remove('wp-dark-mode-animation');
			} else {
				document.querySelector('html').classList.add('wp-dark-mode-animation');
			}
		})
		
	}
}

export default new Accessibility();